.header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  transition: all 0.1s ease-in-out;
  max-width: 64px;
  &:hover {
    transform: scale(1.05);
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.buttons {
  button {
    margin-left: 10px;
  }

  a {
    text-decoration: none;
  }
}

@media (max-width: 800px) {
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 30px;
  }
}
